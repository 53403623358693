<template>
    <div :class="{ 'flex justify-between items-center': goButton }">
        <div :class="{ 'flex justify-between items-center': (styled === 'simple') }">
            <nav aria-label="Page navigation example">
                <ul class="pagination d-inline-flex shadow-sm">

                    <li class="page-item" :class="[{ 'cursor-not-allowed disabled': isFirstPage }, {'disabled': paginationDisabled}]">
                        <a class="tw-table-pagination-next-prev page-link tw-default-text-color" href="#" @click.prevent="changePage(currentPage - 1)" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>

                    <li v-if="hasFirst()" class="page-item" :class="[{'disabled': paginationDisabled}]">
                        <a class="page-link tw-default-text-color" href="#" @click.prevent="changePage(1)">1</a>
                    </li>
                    <li v-if="hasFirst()" class="page-item" :class="[{'disabled': paginationDisabled}]">
                        <span class="page-link tw-default-text-color">...</span>
                    </li>


                    <li class="page-item" v-for="(page, index) in calculatedPages" :key="index" :class="[{'disabled': paginationDisabled}]">
                        <a class="page-link tw-default-text-color" :class="{ [`tw-pagination-active`]: currentPage == page }" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                    </li>


                    <li v-if="hasLast()" class="page-item" :class="[{'disabled': paginationDisabled}]">
                        <span class="page-link tw-default-text-color">...</span>
                    </li>
                    <li v-if="hasLast()" class="page-item" :class="[{'disabled': paginationDisabled}]">
                        <a class="page-link tw-default-text-color" href="#" @click.prevent="changePage(totalPages)">{{ totalPages }}</a>
                    </li>


                    <li class="page-item" :class="[{ 'cursor-not-allowed disabled': isLastPage }, {'disabled': paginationDisabled}]">
                        <a class="tw-table-pagination-next-prev page-link tw-default-text-color" href="#" @click.prevent="changePage(currentPage + 1)" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
            
            <div :class="{ 'order-first': styled === 'simple' }">
                <p class="text-sm text-gray-700">
                    Showing
                    <span class="font-medium">{{ paginationFrom }}</span>
                    to
                    <span class="font-medium">{{ paginationTo }}</span>
                    of
                    <span class="font-medium">{{ totalItems }}</span>
                    results
                </p>
            </div>
        </div>
        <div v-if="goButton">
            <label
                for="search"
                class="pr-3 text-gray-500 text-xs font-semibold uppercase"
            >{{ textBeforeInput }}</label>
            <input
                type="text"
                placeholder="Page no"
                class="w-28 px-4 py-1 rounded-l outline-none border-t-2 border-l-2 border-b-2 placeholder:text-xs uppercase font-semibold"
                :class="`focus:${borderActiveColor}`"
                v-model="pageInput"
            />
            <button
                class="px-4 py-1.5 rounded-r bg-indigo-500 text-white"
                @click.prevent="changePage(pageInput)"
            >{{ textAfterInput }}</button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Pagination',
        emits: ["pageChanged"],
        data: () => ({
            pageInput: 1,
        }),
        methods: {
            changePage(page) {
                if (page > 0 && page <= this.totalPages) {
                    this.$emit('pageChanged', page)
                }
            },
            hasFirst() {
                return this.rangeStart !== 1
            },
            hasLast() {
                return this.rangeEnd < this.totalPages
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalItems / this.perPage);
            },
            paginationFrom() {
                return (this.currentPage - 1) * this.perPage + 1;
            },
            paginationTo() {
                let end = (this.currentPage * this.perPage);
                return this.totalItems < end ? this.totalItems : end;
            },
            isFirstPage() {
                return this.currentPage === 1
            },
            isLastPage() {
                return this.currentPage >= this.totalPages
            },
            rangeStart() {
                let start = this.currentPage - this.pageRange
                return (start > 0) ? start : 1
            },
            rangeEnd() {
                let end = (this.currentPage + this.pageRange)
                return (end < this.totalPages) ? end : this.totalPages
            },
            calculatedPages() {
                let pages = []
                for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
                    pages.push(i)
                }
                return pages
            }
        },
        props: {
            currentPage: {
                type: Number,
                required: true
            },
            totalItems: {
                type: Number,
                required: true
            },
            perPage: {
                type: Number,
                required: true
            },
            pageRange: {
                type: Number,
                default: 2
            },
            goButton: {
                type: Boolean,
                default: false
            },
            textBeforeInput: {
                type: String,
                default: 'Go to page'
            },
            textAfterInput: {
                type: String,
                default: 'Go'
            },
            borderActiveColor: {
                type: String,
                default: 'border-indigo-500'
            },
            borderTextActiveColor: {
                type: String,
                default: 'text-indigo-500'
            },
            styled: {
                type: String,
                default: 'simple'
            },
            paginationDisabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>