<template>
	<section id="tw-view-departments" class="tw-content col-12">
		<div class="bg-white">
			<div class="p-4">
				<div class="tw-table-header row">
					<div class="tw-table-search-wrapper mb-3 mb-xl-0 col-xl-7 text-end">
						<input type="search" class="form-control" v-model="querySearch" placeholder="Search Departments" />
						<span class="tw-table-header-search-icon"><i class="fas fa-search"></i></span>
					</div>
					<div class="col">
						<div class="row">
							<div class="col">
								<div id="departments-category-wrapper" class="position-relative">
									<span class="position-absolute top-0 bottom-0 start-0 end-0 tw-light-grey-bg-color"></span>
									<select class="form-select" v-model="category" id="departments-category">
										<option value="0" selected>Department Category</option>
										<option v-for="category in categories" :value="category.id" v-html="category.name" :key="category.id"></option>
									</select>
								</div>
							</div>
							<div class="col text-end">
                                <!--  v-permission="{juniper: 'create_deparmtnets', tooltip: true}" -->
                                <span class="d-inline-block" v-permission="{juniper: 'create_departments', tooltip: true}">
                                    <router-link class="text-decoration-none d-inline-block" :to="'/departments/create'">
                                        <button class="tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0">
                                            <span class="d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                                                <i class="fas fa-plus"></i>
                                            </span>
                                            <span class="ps-xl-2 d-inline-flex align-items-center flex-grow-1 pe-2">Add Department</span>
                                        </button>
                                    </router-link>
                                </span>
							</div>
						</div>
					</div>
				</div>
				<div class="row position-relative">
					<div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
						<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
					</div>
					<div class="col-12 mb-5">
						<div id="tw-departments-counter-wrapper" class="py-4">
							<span v-if="departments.length === 1" id="tw-departments-counter" class="fw-bold">{{ departments.length }} Department</span>
							<span v-if="departments.length > 1" id="tw-departments-counter" class="fw-bold">{{ departments.length }} Departments</span>
						</div>
					</div>
					<div v-for="(department, index) in departments" :key="index" class="col-md-6 col-lg-4 col-xl-3 mb-3 d-flex">
						<div class="flex-grow-1 d-flex">
							<div class="tw-department-item flex-grow-1 position-relative">
								<router-link class="nav-link tw-default-text-color p-3" :to="'/departments/' + department.slug">
									<div class="tw-department-item-icon mb-2">
										<img v-if="department.icon" :src="(department.icon.media_details && department.icon.media_details.sizes && department.icon.media_details.sizes.thumbnail) ? department.icon.media_details.sizes.thumbnail.source_url : department.icon" :alt="department.title.rendered || department.title" height="25">
										<span v-else v-html="defaultIcon"></span>
									</div>
									<div class="tw-department-item-title fw-bold mb-2">
										{{ department.title.rendered || department.title }}
									</div>
									<div class="tw-department-item-team-count">
										<span v-if="department.team && department.team.length === 1">
											{{ department.team.length }} member
										</span>
										<span v-if="department.team && department.team.length > 1">
											{{ department.team.length }} members
										</span>
									</div>
								</router-link>
								<span v-if="siteType == 'cms6' && userHasCms6Permission('delete_departments')" class="position-absolute top-0 end-0 px-2 py-1 cursor-pointer bg-danger rounded" @click="removeDepartment(department)"><i class="fas fa-trash-alt text-white"></i></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import wordpressMixin from '@/mixins/wordpressMixin';
	import cms6Mixin from '@/mixins/cms6Mixin';
	import siteMixin from '@/mixins/siteMixin';

	let site = localStorage.getObject('site');


	let mixins;

	if (site.type == 'wordpress') {
		mixins = [wordpressMixin, siteMixin];
	} else if(site.type == 'cms6') {
		mixins = [cms6Mixin, siteMixin];
	}

	export default {
		name: 'Departments',
		components: {

		},
		data: () => ({
			departments: [],
			categories: null,
			category: 0,
			querySearch: '',
			requestResolved: false,
			editing: false,
			editingId: null,
			defaultIcon: '<div class="tw-default-icon"><i class="fas fa-image"></i></div>',
		}),
		async mounted() {
			await this.setCategories();
			this.setDepartments();
		},
		watch: {
			querySearch: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setDepartments();
			},
			category: function () {
				// this.fullName = val + ' ' + this.lastName
				// this.posts = [];
				this.setDepartments();
			},
		},
		methods: {
			async setDepartments() {
				this.requestResolved = false;
				let res = await this.getDepartments(parseInt( this.category), this.querySearch);

				if (res && res.status === 200) {
					this.requestResolved = true;
					this.departments = res.data || [];
				}
			},
			async setCategories() {
                let res = await this.getDepartmentsCategories();
                this.categories = await res.data;
            },
            async removeDepartment(department) {

				let msg_html = 'Are you sure you want to delete this department?';

				if (this.siteType == 'cms6' && department.pages_count) {
					msg_html += `<div class="mt-3"><small><i class="text-danger">Also, this department has <b>${department.pages_count} connected page(s)</b>.</i><small></div>`;
				}

				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    html: msg_html,
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {

					if (result.isConfirmed) {

						this.requestResolved = false;

						let res = await this.deleteDepartment(department.id);

						this.setDepartments();

						if (res.status && res.status == 200) {
							this.$swal(
								'Deleted!',
								"Department has been deleted!",
								'success',
							);
						} else {
							this.$swal(
								'Failure!',
								"Department has NOT been deleted!",
								'danger',
							);
						}
					}
                });
			},
            showAddDepartmentView() {
				console.log('add department')
            }
		},
		computed: {
            siteType() {
				return this.getSiteType();
            }
		},
		mixins: mixins
    }
</script>
<style scoped>
	.tw-table-header #departments-category-wrapper span {
		border-radius: 10px;
	}
	.tw-table-header #departments-category {
		border-radius: 10px !important;
		background-color: transparent;
		position: relative;
	}
	#tw-departments-counter-wrapper {
		font-size: 18px;
		border-bottom: 1px solid #E0E0E0;
	}
	.tw-department-item {
		border: 1px solid #E0E0E0;
		box-shadow: 0px 5.67164px 34.0299px rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		transition: .1s;
	}
	.tw-department-item:hover {
		transform: scale(1.05);
	}
	.tw-default-icon i {
		height: 25px;
		font-size: 25px;
	}
	.tw-department-item-title {
		font-size: 24px;
	}
</style>
