// import { customPlugin } from '@/tinymce/plugins/mybuttonMenu/plugin.js';
// import { cuPlugin } from '@/tinymce/plugins/myCustomToolbarButton/plugin.js';
// import wordpressMixin from '@/mixins/wordpressMixin';
import axios from 'axios'
const config = {
    height: 500,
    content_style: "body#tinymce { margin-bottom: 50px; }",
    menubar: false,
    // plugins: [
    //         'advlist autolink lists link image charmap print preview anchor',
    //         'searchreplace visualblocks code fullscreen',
    //         'insertdatetime media table paste code help wordcount'
    // ],
    plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount contextmenu'
    ],
    contextmenu: false, 
    // toolbar:
    // 'undo redo | formatselect | bold italic backcolor | \
    //     alignleft aligncenter alignright alignjustify | \
    //     bullist numlist outdent indent | removeformat | help'
    toolbar:
    'formatselect | bold italic underline strikethrough | \
        alignleft aligncenter alignright alignjustify | \
        numlist bullist outdent indent | forecolor backcolor | link media image | removeformat',
    // images_upload_url: 'http://localhost/tw/clayton-theme/wp-json/wp/v2/media'
    images_upload_handler: file_upload_handler,
    file_picker_callback: function(callback, value, meta) {
        // Provide file and text for the link dialog
        if (meta.filetype == 'file') {
            console.log(value)
            console.log(meta)
            callback('mypage.html', {text: 'My text'});
        }

        // Provide image and alt text for the image dialog
        if (meta.filetype == 'image') {
          // callback('myimage.jpg', {alt: 'My alt text'});
        }

        // Provide alternative source and posted for the media dialog
        if (meta.filetype == 'media') {
          // callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
        }
    },
    file_browser_callback_types: 'file',
    // file_browser_callback_types: 'file image media'
    // file_browser_callback: function(field_name, url, type, win) {
    //     win.document.getElementById(field_name).value = 'my browser value';
    // }
    // files_upload_handler: file_upload_handler
    setup: function () {

        // Here we can add plugin 
        // window.tinymce.PluginManager.add('myCustomToolbarButton', cuPlugin);
        // window.tinymce.PluginManager.add('mybutton', customPlugin);

        

        
    }
}

export default { config };


async function file_upload_handler (blobInfo, success, failure, progress) {

    let user = sessionStorage.getObject('user') || localStorage.getObject('user');

    if (!user || !user.auth) {
        return;
    }


    // let namespace = namespace || '/twd-dashboard/v1';
    let site = localStorage.getObject('site');


    console.log(site.type)

    let res,headers;

    var formData = new FormData();


    formData.append('file', blobInfo.blob(), blobInfo.filename());

    if (site.type == 'wordpress') {
        
        headers = { Authorization: 'Basic ' + user.auth };
    
    
    
    
    
        res = await axios.post(site.url + '/wp-json/wp/v2/media', formData, {
                                        headers: {...headers,...{ 'Content-Type': 'multipart/form-data'}},
                                        onUploadProgress: e => progress(e.loaded / e.total * 100)
                                    })
    } else if (site.type == 'cms6') {

        headers = { Authorization: "Bearer " + user.auth };

        res = await axios.post(process.env.VUE_APP_JUNIPER_API + "media", formData, {
            headers: { ...headers, ...{ "Content-Type": "multipart/form-data" } },
            onUploadProgress: e => progress(e.loaded / e.total * 100)
        });
    }

    if (res.status === 200 || res.status === 201) {
        success(res.data.source_url);
    } else {
        failure('Failure');
    }
}
